<template>
	<div class="flex h-screen overflow-hidden bg-gray-100">
		<DontPurge />

		<TransitionRoot as="template" :show="sidebarOpen">
			<Dialog
				as="div"
				static
				class="fixed inset-0 z-40 flex md:hidden"
				:open="sidebarOpen"
				@close="sidebarOpen = false"
			>
				<TransitionChild
					as="template"
					enter="transition-opacity ease-linear duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
				</TransitionChild>
				<TransitionChild
					as="template"
					enter="transition ease-in-out duration-300 transform"
					enter-from="-translate-x-full"
					enter-to="translate-x-0"
					leave="transition ease-in-out duration-300 transform"
					leave-from="translate-x-0"
					leave-to="-translate-x-full"
				>
					<div class="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-800">
						<TransitionChild
							as="template"
							enter="ease-in-out duration-300"
							enter-from="opacity-0"
							enter-to="opacity-100"
							leave="ease-in-out duration-300"
							leave-from="opacity-100"
							leave-to="opacity-0"
						>
							<div class="absolute top-0 right-0 pt-2 -mr-12">
								<button
									class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:ring-inset focus:ring-white"
									@click="sidebarOpen = false"
								>
									<span class="sr-only">Close sidebar</span>
									<XIcon class="w-6 h-6 text-white" aria-hidden="true" />
								</button>
							</div>
						</TransitionChild>
						<div class="flex items-center flex-shrink-0 px-4">
							<router-link to="/" class="flex items-center flex-shrink-0 h-16 px-4">
								<Logo
									style="height: 75px; margin-left: 25px"
									:fill="{
										blue: '#ffffff',
										green: '#ffffff',
										yellow: '#ffffff',
									}"
								/>
							</router-link>
						</div>
						<div class="flex-1 h-0 mt-5 overflow-y-auto">
							<nav class="px-2 space-y-1 side-menu">
								<template v-for="item in navigation" :key="item.name">
									<div v-if="!item.children">
										<router-link
											:to="item.path"
											:class="[
												currentRouteName.includes(item.path) && item.path !== '/'
													? 'active'
													: '',
												'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
											]"
										>
											<component :is="item.icon" class="w-6 h-6 mr-3" aria-hidden="true" />
											{{ item.name }}
										</router-link>
									</div>
									<Disclosure v-else v-slot="{ open }" as="div" class="space-y-1">
										<DisclosureButton
											:class="[
												currentRouteName.includes(item.path) && item.path !== '/'
													? 'active'
													: '',
												'group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md focus:ring-indigo-500',
											]"
										>
											<component :is="item.icon" class="w-6 h-6 mr-3" aria-hidden="true" />
											{{ item.name }}
											<svg
												:class="[
													open ? 'text-gray-400 rotate-90' : 'text-gray-300',
													'ml-auto h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
												]"
												viewBox="0 0 20 20"
												aria-hidden="true"
											>
												<path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
											</svg>
										</DisclosureButton>
										<DisclosurePanel class="space-y-1">
											<router-link
												v-for="subItem in item.children"
												:key="subItem.name"
												:to="subItem.path"
												:class="[
													currentRouteName.includes(subItem.path) && item.path !== '/'
														? 'active'
														: '',
													'group flex items-center pl-5 pr-2 py-2 text-sm font-medium rounded-md',
												]"
											>
												<component :is="subItem.icon" class="w-6 h-6 mr-3" aria-hidden="true" />
												{{ subItem.name }}
											</router-link>
										</DisclosurePanel>
									</Disclosure>
								</template>
							</nav>
						</div>
					</div>
				</TransitionChild>
				<div class="flex-shrink-0 w-14" aria-hidden="true">
					<!-- Dummy element to force sidebar to shrink to fit close icon -->
				</div>
			</Dialog>
		</TransitionRoot>

		<!-- Static sidebar for desktop -->
		<div class="hidden md:flex md:flex-shrink-0">
			<div class="flex flex-col w-64">
				<!-- Sidebar component, swap this element with another sidebar if you like -->
				<div class="flex flex-col flex-1 h-0 bg-gray-800">
					<router-link to="/" class="flex items-center flex-shrink-0 h-16 px-4 bg-gray-800">
						<Logo
							style="height: 55px; margin-left: 20px"
							:fill="{
								blue: '#ffffff',
								green: '#ffffff',
								yellow: '#ffffff',
							}"
						/>
					</router-link>
					<div class="flex flex-col flex-1 overflow-y-auto">
						<nav class="flex-1 px-2 py-4 space-y-1 bg-gray-800 side-menu">
							<template v-for="item in filterNavByRole" :key="item.name">
								<div v-if="!item.children">
									<router-link
										:to="item.path"
										:class="[
											currentRouteName.includes(item.path) && item.path !== '/' ? 'active' : '',
											'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
										]"
									>
										<component :is="item.icon" class="w-6 h-6 mr-3" aria-hidden="true" />
										{{ item.name }}
									</router-link>
								</div>
								<Disclosure v-else v-slot="{ open }" as="div" class="space-y-1">
									<!-- Root Node of menu with children -->
									<DisclosureButton
										:class="[
											currentRouteName.includes(item.path) && item.path !== '/' ? 'active' : '',
											'group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md focus:ring-indigo-500',
										]"
									>
										<component :is="item.icon" class="w-6 h-6 mr-3" aria-hidden="true" />
										{{ item.name }}
										<svg
											:class="[
												open ? 'text-gray-400 rotate-90' : 'text-gray-300',
												'ml-auto h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
											]"
											viewBox="0 0 20 20"
											aria-hidden="true"
										>
											<path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
										</svg>
									</DisclosureButton>
									<!-- Nested Children under root element -->
									<DisclosurePanel class="space-y-1">
										<router-link
											v-for="subItem in item.children"
											:key="subItem.name"
											:to="subItem.path"
											:class="[
												currentRouteName.includes(subItem.path) && item.path !== '/'
													? 'active'
													: '',
												'group flex items-center pl-5 pr-2 py-2 text-sm font-medium rounded-md',
											]"
										>
											<component :is="subItem.icon" class="w-6 h-6 mr-3" aria-hidden="true" />
											{{ subItem.name }}
										</router-link>
									</DisclosurePanel>
								</Disclosure>
							</template>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-col flex-1 w-0 overflow-hidden">
			<div class="relative z-10 flex flex-shrink-0 h-16 bg-white shadow">
				<button
					class="px-4 text-gray-500 border-r border-gray-200 focus:ring-inset focus:ring-indigo-500 md:hidden"
					@click="sidebarOpen = true"
				>
					<span class="sr-only">Open sidebar</span>
					<MenuAlt2Icon class="w-6 h-6" aria-hidden="true" />
				</button>
				<div class="flex justify-between flex-1 px-4">
					<div class="flex items-center flex-1">
						<div v-if="env != 'production'" id="environment" class="ml-2 font-semibold text-sc-green">
							{{ env }} Environment
						</div>
					</div>
					<div class="flex items-center ml-4 md:ml-6">
						<!-- Notifications -->
						<Menu as="div" class="relative ml-3">
							<div>
								<MenuButton>
									<button
										class="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none"
									>
										<span class="sr-only">View notifications</span>
										<BellIcon class="w-6 h-6" aria-hidden="true" />
									</button>
								</MenuButton>
							</div>
							<transition
								enter-active-class="transition duration-100 ease-out"
								enter-from-class="transform scale-95 opacity-0"
								enter-to-class="transform scale-100 opacity-100"
								leave-active-class="transition duration-75 ease-in"
								leave-from-class="transform scale-100 opacity-100"
								leave-to-class="transform scale-95 opacity-0"
							>
								<MenuItems
									class="absolute right-0 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
									style="width:400px;"
								>
									<MenuItem v-for="(notification, index) in notifications" :key="index">
										<div class="relative pb-8">
											<div class="relative flex space-x-3">
												<div>
													<span
														class="flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full ring-8 ring-white"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															class="w-5 h-5 text-white"
															viewBox="0 0 20 20"
															fill="currentColor"
														>
															<path
																fill-rule="evenodd"
																d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
																clip-rule="evenodd"
															/>
														</svg>
													</span>
												</div>
												<div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
													<div>
														<p class="text-sm text-gray-500">
															{{ notification.message }}
														</p>
													</div>
													<div class="text-sm text-right text-gray-500 whitespace-nowrap">
														<time datetime="2021-07-14">8 minutes ago</time>
													</div>
												</div>
											</div>
										</div>
									</MenuItem>
								</MenuItems>
							</transition>
						</Menu>
						<!-- End Notifications -->

						<!-- Profile dropdown -->
						<Menu as="div" class="relative ml-3">
							<div>
								<MenuButton
									class="flex items-center max-w-xs text-sm bg-white rounded-full gap-x-2 focus:outline-none"
								>
									<p v:if="loggedIn">{{ userName }}</p>
									<!--<p v:if="loggedIn">{{ store.state.user.current.name }}</p>-->
									<span class="sr-only">Open user menu</span>
									<DotsVerticalIcon class="w-6 h-6 text-gray-600" aria-hidden="true" />
									<!-- <img
										class="w-8 h-8 rounded-full"
										src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
										alt=""
									/> -->
								</MenuButton>
							</div>
							<transition
								enter-active-class="transition duration-100 ease-out"
								enter-from-class="transform scale-95 opacity-0"
								enter-to-class="transform scale-100 opacity-100"
								leave-active-class="transition duration-75 ease-in"
								leave-from-class="transform scale-100 opacity-100"
								leave-to-class="transform scale-95 opacity-0"
							>
								<MenuItems
									class="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
								>
									<MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
										<router-link
											:to="item.path"
											:class="[
												active ? 'bg-gray-100' : '',
												!item.enabled ? 'disabled opacity-50' : '',
												'block px-4 py-2 text-sm text-gray-700',
											]"
										>
											{{ item.name }}
										</router-link>
									</MenuItem>
								</MenuItems>
							</transition>
						</Menu>
					</div>
				</div>
			</div>

			<main id="main-content" ref="mainContent" class="relative flex-1 overflow-y-auto focus:outline-none">
				<div class="h-full py-6">
					<div class="h-full px-4 mx-auto overflow-x-hidden">
						<!-- Replace with your content -->
						<router-view :key="$route.fullPath" />
						<!-- /End replace -->
					</div>
				</div>
			</main>
		</div>

		<Loader v-show="store.state.loader.loading" />
	</div>
</template>

<script>
import { inject, getCurrentInstance } from 'vue'
import { computed, ref, shallowRef, onMounted } from 'vue'
import { useStore } from 'vuex'
import getEnv from '../extensions/env'
import {
	Dialog,
	DialogOverlay,
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue'

import {
	BeakerIcon,
	LibraryIcon,
	BellIcon,
	CalendarIcon,
	ClipboardListIcon,
	CogIcon,
	DocumentIcon,
	ExclamationIcon,
	FilmIcon,
	HomeIcon,
	LinkIcon,
	LockOpenIcon,
	MenuAlt2Icon,
	NewspaperIcon,
	OfficeBuildingIcon,
	TemplateIcon,
	UserCircleIcon,
	UserGroupIcon,
	UsersIcon,
	ViewGridIcon,
	XIcon,
	DotsVerticalIcon,
	CollectionIcon,
	DocumentReportIcon,
	FastForwardIcon,
	BadgeCheckIcon,
} from '@heroicons/vue/outline'

// import { SearchIcon } from "@heroicons/vue/solid";

export default {
	components: {
		DotsVerticalIcon,
		LibraryIcon,
		BeakerIcon,
		BellIcon,
		CalendarIcon,
		ClipboardListIcon,
		CogIcon,
		Dialog,
		DialogOverlay,
		Disclosure,
		DisclosureButton,
		DisclosurePanel,
		DocumentIcon,
		ExclamationIcon,
		FilmIcon,
		LinkIcon,
		LockOpenIcon,
		Menu,
		MenuAlt2Icon,
		MenuButton,
		MenuItem,
		MenuItems,
		NewspaperIcon,
		OfficeBuildingIcon,
		TemplateIcon,
		TransitionChild,
		TransitionRoot,
		UserCircleIcon,
		UserGroupIcon,
		ViewGridIcon,
		XIcon,
		CollectionIcon,
		DocumentReportIcon,
		FastForwardIcon,
		BadgeCheckIcon,
	},

	setup() {
		// const api = inject('api')

		const store = useStore()
		const broadcast = inject('broadcast')
		// broadcast.listen('yext', 'YextListFetchComplete')
		// broadcast.listen('yext', 'YextFetchComplete')
		broadcast.listen('yext', [
			'YextFetchComplete',
			'YextListFetchComplete',
			'YextDeleteComplete',
			'YextCreateComplete',
			'YextUpdateComplete',
			'YextUpdateAllComplete',
			'YextUnpublishComplete',
		])

		const env = getEnv('VUE_APP_ENVIRONMENT')
		const isProd = env == 'production'
		const sidebarOpen = shallowRef(false)
		const open = shallowRef(false)
		const loggedIn = computed(() => store.state.user.loaded)
		//console.log('Logged In: ', loggedIn.value)
		const userName = computed(() => store.state.user.current.name)
		const userRoles = computed(() => store.state.user.current.roles)

		onMounted(() => {
			console.log('ROLES', userRoles.value)
		})

		const notifications = shallowRef([
			{
				name: 'Test',
				message: 'TestMessage',
			},
		])

		const navigation = shallowRef([
			{
				name: 'Dashboard',
				path: '/',
				icon: HomeIcon,
			},
			{
				name: 'Content',
				path: '/content',
				icon: TemplateIcon,
				roles: ['Super Admin', 'Admin'],
				children: [
					{
						name: 'Main Navigation',
						path: '/content/navigation',
						icon: LinkIcon,
					},
					{
						name: 'Pages',
						path: '/content/pages',
						icon: DocumentIcon,
					},
					{
						name: 'News',
						path: '/content/articles',
						icon: NewspaperIcon,
					},
					{
						name: 'Events',
						path: '/content/events',
						icon: CalendarIcon,
					},
					{
						name: 'Media',
						path: '/content/media',
						icon: FilmIcon,
					},
					{
						name: 'Badges',
						path: '/content/badges',
						icon: BadgeCheckIcon,
					},
					{
						name: 'Forms',
						path: '/content/forms',
						icon: ClipboardListIcon,
					},
					{
						name: 'Services',
						path: '/content/services',
						icon: ViewGridIcon,
					},
					{
						name: 'Amenities',
						path: '/content/amenities',
						icon: LibraryIcon,
					},
					{
						name: 'Clinical Research',
						path: '/content/clinical-research',
						icon: BeakerIcon,
					},
					{
						name: 'Anouncement Banners',
						path: '/content/announcement-banners',
						icon: ExclamationIcon,
						wip: true,
					},
					{
						name: 'Detours',
						path: '/content/detours',
						icon: FastForwardIcon,
					},
				],
			},
			{
				name: 'MD-Staff',
				path: '/md-staff',
				icon: UsersIcon,
				roles: ['Super Admin', 'Admin'],
				children: [
					{
						name: 'Providers',
						path: '/md-staff/providers',
						icon: UserCircleIcon,
					},
					{
						name: 'Locations',
						path: '/md-staff/locations',
						icon: OfficeBuildingIcon,
					},
					{
						name: 'Departments',
						path: '/md-staff/departments',
						icon: UserGroupIcon,
					},
				],
			},
			{
				name: 'Reports',
				path: '/reports',
				icon: DocumentReportIcon,
				roles: ['Super Admin', 'Admin', 'Event Coordinator'],
				children: [
					// {
					// 	name: 'Content',
					// 	path: '/reports/content',
					// 	icon: TemplateIcon,
					// 	wip: true,
					// },
					{
						name: 'Events',
						path: '/reports/events',
						icon: CalendarIcon,
						roles: ['Super Admin', 'Admin', 'Event Coordinator'],
					},
					{
						name: 'MD-Staff',
						path: '/reports/md-staff',
						icon: UsersIcon,
						roles: ['Super Admin', 'Admin'],
					},
				],
			},
			{
				name: 'Administration',
				path: '/admin',
				icon: CogIcon,
				roles: ['Super Admin', 'Admin'],
				children: [
					{
						name: 'Users',
						path: '/administration/users',
						icon: UsersIcon,
					},
					{
						name: 'Roles',
						path: '/administration/roles',
						icon: UserGroupIcon,
						wip: true,
					},
					{
						name: 'Permissions',
						path: '/administration/permissions',
						icon: LockOpenIcon,
						wip: true,
					},
				],
			},
		])

		const userNavigation = shallowRef([
			{ name: 'Your Profile', path: '#', enabled: false },
			{ name: 'Settings', path: '#', enabled: false },
			{ name: 'Sign out', path: '/logout', enabled: true },
		])

		const currentMenuItem = shallowRef(0)

		return {
			// api,
			broadcast,
			env,
			isProd,
			store,
			sidebarOpen,
			open,
			userName,
			userRoles,
			navigation,
			userNavigation,
			currentMenuItem,
			notifications,
		}
	},
	computed: {
		currentRouteName() {
			return this.$route.path
		},
		filterNavByWIP() {
			// If we're anything but prod, return the full navigation
			if (!this.isProd) return this.navigation
			// If we're prod, filter out the WIP menu navigation routes
			let nav = this.navigation.filter(nav => !nav.wip)
			nav.forEach(mainElement => {
				if (mainElement.children) mainElement.children = mainElement.children.filter(navChild => !navChild.wip)
			})
			return nav
		},
		filterNavByRole() {
			// Get WIP filtered menu first
			let nav = this.filterNavByWIP

			// Filter menu items by role
			nav = nav.filter((navItem, navItemIndex) => {
				let hasRole = false
				if (typeof navItem.roles !== 'undefined') {
					navItem.roles.forEach(role => {
						if (this.userRoles.findIndex(x => x === role) >= 0) {
							hasRole = true
						}
					})
				} else {
					hasRole = true
				}

				// Filter sub menu items by role if they exist
				if (navItem.children) {
					navItem.children = this.filterChildren(navItem.children)
				}

				return hasRole
			})

			// console.log('nav', nav)

			return nav
		},
	},

	mounted() {
		// this.echo
		// 	.channel('yext')
		// 	// .private('yext')
		// 	.listen('YextListFetchComplete', e => console.log('YextListFetchComplete: ' + e.message))
	},
	methods: {
		filterChildren(children) {
			children = children.filter(child => {
				let hasRole = false
				if (typeof child.roles !== 'undefined') {
					child.roles.forEach(role => {
						if (this.userRoles.findIndex(x => x === role) >= 0) {
							hasRole = true
						}
					})
				} else {
					hasRole = true
				}

				return hasRole
			})

			return children
		},
	},
}
</script>
<style>
#environment:first-letter {
	text-transform: capitalize;
}
</style>
